import React, { useState } from 'react';
import { createContent } from '../common/Theme';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import Layout from '../components/layout';
import Button from "../components/button"
import {
  Container,
  Grid,
  Typography,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  button: {
    paddingTop: '.1rem',
    paddingBottom: '.1rem'
  },
  typography: {
    marginBottom: '2rem'
  },
  card: {
    backgroundColor: 'transparent'
  },
  formCardContent: {
    padding: '2rem 8rem 2rem 0'
  },
  input: {
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    }

  }
})

const ContactPage = () => {
  const content = createContent();
  const classes = useStyles();

  const { control, handleSubmit, reset, formState } = useForm();
  const { isSubmitted } = formState;

  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = (data) => {
    axios
      .post('https://9nc34yr5qe.execute-api.eu-west-1.amazonaws.com/production', data, { headers: { "Content-Type": 'application/json' } })
      .then((response) => {
        setIsSuccess(true);
        reset({ name: '', email: '', message: '', phone: '' });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Layout>
      <Container classes={{root: content.content}}>
        <div className='inner-container'>

        <Typography variant='h1' classes={{root: classes.typography}}>Let's talk</Typography>
        <Typography className='text-large' classes={{root: classes.typography}}>Start a project, schedule a talk or just say hello</Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            {/*<Card elevation={0} classes={{root: classes.card}}>*/}
            {/*  <CardContent classes={{root: classes.formCardContent}}>*/}
                <form id='contact-form' autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name='name'
                    control={control}
                    rules={{ required: 'Name is required' }}
                    defaultValue=''
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id='name'
                        label='Name *'
                        color='primary'
                        variant='filled'
                        fullWidth={true}
                        margin='normal'
                        classes={{root: classes.input}}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: 'Email is required' }}
                    defaultValue=''
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id='email'
                        label='Email *'
                        color='primary'
                        variant='filled'
                        fullWidth={true}
                        margin='normal'
                        classes={{root: classes.input}}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <Controller
                    name='phone'
                    control={control}
                    defaultValue=''
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id='name'
                        label='Phone'
                        color='primary'
                        variant='filled'
                        fullWidth={true}
                        margin='normal'
                        classes={{root: classes.input}}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <Controller
                    name='message'
                    control={control}
                    rules={{ required: 'Message is required' }}
                    defaultValue=''
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id='message'
                        label='Message *'
                        multiline={true}
                        rows={4}
                        color='primary'
                        variant='filled'
                        fullWidth={true}
                        margin='normal'
                        value={value}
                        classes={{root: classes.input}}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                  <div className='form-footer'>
                    <Button
                      text='Submit form'
                      type='submit'
                      form='contact-form'
                      disabled={isSubmitted}
                    />

                    {
                      isSuccess &&
                      <Alert
                        severity="success"
                        variant='outlined'
                        onClose={() => setIsSuccess(false)}
                      >
                        Thanks for your message!
                      </Alert>
                    }
                  </div>
                </form>
              {/*</CardContent>*/}
            {/*</Card>*/}
          </Grid>
          {/*<Grid item xs={12} sm={12} md={4}>*/}
          {/*  <Card elevation={0} classes={{root: classes.card}}>*/}
          {/*    <CardContent>*/}
          {/*      <Typography variant='h4' color='textPrimary'>Syntactful</Typography>*/}
          {/*      <div className='address-line'>*/}
          {/*        <MailIcon />*/}
          {/*        <a href='mailto:info@syntactful.com' className='hoverable'>*/}
          {/*          <Typography>*/}
          {/*            info@syntactful.com*/}
          {/*          </Typography>*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <div className='address-line'>*/}
          {/*        <PhoneIphoneIcon />*/}
          {/*        <a href='tel:+32496685837' className='hoverable'>*/}
          {/*          <Typography>*/}
          {/*            +32 496 68 58 37*/}
          {/*          </Typography>*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*      <div className='address-line'>*/}
          {/*        <LocationCityIcon />*/}
          {/*        <div>*/}
          {/*          <Typography>*/}
          {/*            Begijnhof 17*/}
          {/*          </Typography>*/}
          {/*          <Typography>*/}
          {/*            2320 Hoogstraten, Belgium*/}
          {/*          </Typography>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
        </Grid>

        </div>
      </Container>
    </Layout>
  )
}

export default ContactPage
